<template>
  <div>
    <b-row>
      <b-col>
        <b-modal
          id="executivo"
          ref="executivo"
          title="Adicionar executivo"
          ok-title="Cadastrar Executivo"
          cancel-title="Cancelar"
          cancel-variant="danger"
          @ok="addExecutivo"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              Nome:
              <b-form-input
                v-model="executivo.usuario"
              />
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              Senha Fastway:
              <b-form-input
                v-model="executivo.senha"
              />
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              Ramal:
              <b-form-input
                v-model="executivo.ramal"
              />
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              Meta:
              <b-form-input
                v-model="executivo.meta"
              />
            </b-col>
          </b-row>
        </b-modal>
      </b-col>
    </b-row>
    <b-card>
      <b-col
        cols="12"
      >
        <b-row
          align-h="between"
          class="mb-2"
        >
          <b-col lg="1">
            <b-form-select
              v-model="perPage"
              :options="[10, 15, 20]"
              size="sm"
            />
          </b-col>
          <b-col
            lg="8"
            class="d-flex justify-content-end"
          >
            <b-form-input
              v-model="filter"
              size="sm"
              placeholder="Pesquisar"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-row>
        <b-col>
          <b-table
            responsive
            hover
            bordered
            :filter="filter"
            :per-page="perPage"
            :current-page="currentPage"
            :items="funcionarios"
            :fields="fields"
            @filtered="onFiltered"
          >
            <template #cell(acoes)="data">
              <b-row
                align="center"
              >
                <b-col>
                  <feather-icon
                    :id="`view-${data.item.funcionarios_id}`"
                    size="16"
                    class="plus-icon"
                    icon="PlusIcon"
                    @click="clikedExecutivo(data.item)"
                  />
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BPagination,
  BModal,
  BFormSelect,
  BFormInput,
  BCard,
  BRow,
  BCol,
  BTable,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import axios from '@/../axios-auth'

export default {
  components: {
    BPagination,
    BModal,
    BFormSelect,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BTable,
  },
  data() {
    return {
      edit: false,
      executivo: {
        funcionarios_id: 1,
        usuario: null,
        senha: null,
        ramal: null,
        meta: null,
      },
      filter: '',
      fields: [
        'nome',
        { key: 'funcao', label: 'função' },
        { key: 'acoes', label: 'ações', class: 'text-center' },
      ],
      funcionarios: [],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
    }
  },
  created() {
    axios.get('api/v1/funcionarios', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => {
        this.funcionarios.push({
          id: dt.id,
          nome: dt.nome,
          funcao: dt.funcao.nome,
        })
      })
      this.totalRows = this.funcionarios.length
    })
  },
  methods: {
    // rowClicked(item) {
    //   this.$refs.editexecutivo.show()
    //   this.executivo = {
    //     funcionarios_id: item.id,
    //     usuario: item.usuario,
    //     senha: item.senha,
    //     ramal: item.ramal,
    //     meta: item.meta,
    //   }
    // },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    clikedExecutivo(item) {
      this.$refs.executivo.show()
      this.executivo = {
        funcionarios_id: item.id,
        usuario: item.nome,
        senha: null,
        ramal: null,
        meta: null,
      }
    },
    async addExecutivo() {
      const body = {
        funcionarios_id: this.executivo.funcionarios_id,
        usuario: this.executivo.usuario,
        senha: this.executivo.senha,
        ramal: this.executivo.ramal,
        meta: this.executivo.meta,
      }
      axios.post('api/v1/executivo/store', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/jason',
        },
      }).then(() => {
        this.$swal({
          title: 'CADASTRADO!',
          text: 'Executivo cadastrado com sucesso',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            comfirmButton: 'btn btn-primary',
          },
        })
      }).catch(() => {
        this.$swal({
          title: 'ERROR!',
          text: 'Algo deu errado',
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      })
    },
    // async updateExecutivo(item) {
    //   axios.put(`api/v1/executivo/update/${item.id}`, this.executivo, {
    //     headers: {
    //       Authorization: 'Bearer '.concat(localStorage.getItem('token')),
    //       accept: 'application/json',
    //     },
    //   }).then(() => {
    //   }).catch(error => {
    //     this.$swal({
    //       title: 'Erro ao Salvar!',
    //       text: error,
    //       icon: 'error',
    //       customClass: {
    //         confirmButton: 'btn btn-primary',
    //       },
    //       buttonsStyling: false,
    //     })
    //   })
    // },
  },
}
</script>
